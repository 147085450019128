/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"

const Layout = ({children}) => {
    return (
        <>
            <div
                style={{
                    margin: `0 auto`,
                    padding: `0`,
                    paddingTop: 0,
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    width: '100%'
                }}
            >
                <main
                    style={{flexGrow: 1}}>
                    {children}</main>
                <footer>
                </footer>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
